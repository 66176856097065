<template lang="pug">
div
  Breadcrumbs(:breadcrumbs="breadcrumbs")

  TableV2(
    :actions="actions"
    :headers="headers"
    :isLoading="isLoading"
    :items="items"
    :querySearch="getData"
    :saveReport="getCrewingManagersReportExcel"
  ).px-4
</template>

<script>
import { mapActions, mapState } from 'vuex'
export default {
  name: 'TableManager',
  components: {
    Breadcrumbs: () => import('@/components/atoms/Breadcrumbs')
  },
  data () {
    return {
      headers: [
        { value: 'crewing_name', text: this.$t('crewingCompanyName') },
        { value: 'manager_full_name', text: this.$t('crewingManager') },
        { value: 'sailor_full_name', text: this.$t('sailorFullName') },
        { value: 'created_at', text: this.$t('cooperationDate') },
        { value: 'date_end_proxy', text: this.$t('contractDateEnd') },
        { value: 'event', text: this.$t('actions'), align: 'center', sortable: false }
      ],
      actions: [
        {
          id: 1,
          to: (item) => ({ name: 'sailor', params: { id: item.sailor_key } }),
          color: 'blue',
          target: '_blank',
          name: 'mdi-account-circle-outline',
          tooltip: 'tooltip.goToSailor',
          buttonOptions: { xSmall: false, icon: true }
        }
      ],
      breadcrumbs: {
        historyBreadcrumbs: [
          {
            text: this.$t('companies'),
            link: { name: 'crewing-connect-company-report', params: { ...this.$route.params } },
            disabled: this.$route.name === 'crewing-connect-company-report'
          },
          {
            text: this.$t('managers'),
            link: { name: 'crewing-connect-manager-report', params: { ...this.$route.params }, query: { ...this.$route.query } },
            disabled: this.$route.name === 'crewing-connect-manager-report'
          },
          {
            text: this.$t('sailors'),
            link: { name: 'crewing-connect-sailor-report', params: { ...this.$route.params }, query: { ...this.$route.query } },
            disabled: this.$route.name === 'crewing-connect-sailor-report'
          }
        ],
        checkAccess: () => true
      }
    }
  },
  computed: {
    ...mapState({
      items: state => state.report.listCrewingSailors,
      isLoading: state => state.report.isLoadingReport
    })
  },
  methods: {
    ...mapActions(['getListCrewingSailors', 'getCrewingSailorsReportExcel']),
    async getData (params) {
      this.getListCrewingSailors({ params })
    }

  }
}
</script>
